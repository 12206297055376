export const tr = {
    translation: {
        "welcome": "Tekrar Hoşgeldin!",
        "join_us": "Aramıza Katıl",
        "email": "E-Mail",
        "password": "Şifre",
        "confirm_password": "Şifreyi Onayla",
        "login": "Giriş",
        "register": "Kayıt ol",
        "by_continue": "Devam ederek Riwai'nin Hizmet Şartları ve Gizlilik Politikası'nı kabul etmiş olursunuz.",
        "error_email_already_in_use": "Bu e-posta adresi zaten kullanılıyor. Giriş sayfasına gidin.",
        "error_wrong_password": "Yanlış e-posta/şifre kombinasyonu.",
        "error_user_not_found": "Bu e-posta ile eşleşen bir kullanıcı bulunamadı.",
        "error_user_disabled": "Kullanıcı devre dışı bırakılmış.",
        "error_too_many_requests": "Bu hesaba giriş yapmak için çok fazla istek gönderildi.",
        "error_operation_not_allowed": "Sunucu hatası, lütfen daha sonra tekrar deneyin.",
        "error_invalid_email": "E-posta adresi geçersiz.",
        "error_weak_password": "Şifre çok zayıf.",
        "error_invalid_verification_code": "Doğrulama kodu geçersiz.",
        "error_invalid_verification_id": "Doğrulama kimliği geçersiz.",
        "error_credential_already_in_use": "Bu kimlik bilgisi zaten farklı bir kullanıcı hesabıyla ilişkili.",
        "error_requires_recent_login": "Bu işlem hassastır ve yakın zamanda yapılan kimlik doğrulamasını gerektirir. Bu isteği yeniden denemeden önce tekrar giriş yapın.",
        "error_missing_email": "E-posta gerekli ancak eksik.",
        "error_email_already_exists": "Bu e-posta zaten mevcut.",
        "error_phone_number_already_exists": "Bu telefon numarası zaten mevcut.",
        "error_invalid_credential": "Kimlik bilgileri geçersiz.",
        "login_failed": "Giriş başarısız. Lütfen tekrar deneyin.",
        "play": "Oynat",
        "stop": "Durdur",
        "next": "Sonraki",
        "previous": "Önceki",
        "loop": "Tekrarla"
    }
};
