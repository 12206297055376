import styles from "./language-icon-button.module.scss";
import { WButton } from "../../wrapper/button/button";
import { WIcon } from "../../wrapper/icon/icon";
import { useTranslation } from "react-i18next";

export default function LanguageIconButton({ className }: { className?: string }) {
    const { i18n } = useTranslation();

    const handleLanguageChange = (language: string) => {
        i18n.changeLanguage(language)
        localStorage.setItem("lang", language)
    }
    return (
        <WButton className={`${styles["button"]} ${className}`}>
            <WIcon name="language" />
            <select
                value={i18n.language}
                onChange={e => handleLanguageChange(e.target.value)}
            >
                <option value="tr">Türkçe</option>
                <option value="en">English</option>
            </select>
        </WButton>
    );
}