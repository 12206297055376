class IHelperService {
    public getAuthErrorMessage(t: any, code: string) {

        const errorMessages: { [key: string]: string } = {
            "auth/email-already-in-use": t('error_email_already_in_use'),
            "auth/wrong-password": t('error_wrong_password'),
            "auth/user-not-found": t('error_user_not_found'),
            "auth/user-disabled": t('error_user_disabled'),
            "auth/too-many-requests": t('error_too_many_requests'),
            "auth/operation-not-allowed": t('error_operation_not_allowed'),
            "auth/invalid-email": t('error_invalid_email'),
            "auth/weak-password": t('error_weak_password'),
            "auth/invalid-verification-code": t('error_invalid_verification_code'),
            "auth/invalid-verification-id": t('error_invalid_verification_id'),
            "auth/credential-already-in-use": t('error_credential_already_in_use'),
            "auth/requires-recent-login": t('error_requires_recent_login'),
            "auth/missing-email": t('error_missing_email'),
            "auth/email-already-exists": t('error_email_already_exists'),
            "auth/phone-number-already-exists": t('error_phone_number_already_exists'),
            "auth/invalid-credential": t('error_invalid_credential'),
        };

        return errorMessages[code] || t('login_failed');
    }

    public secondsToTime(e: number) {
        const
            m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
            s = Math.floor(e % 60).toString().padStart(2, '0');

        return m + ':' + s;
    }
}

export const HelperService = new IHelperService();
