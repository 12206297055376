import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './i18n';
import Login from './pages/auth/login/login';
import { ChakraProvider } from '@chakra-ui/react';
import { UserContextProvider } from './contexts/user';
import Register from './pages/auth/register/register';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/register",
    element: <Register />
  }
]);



root.render(
  <React.StrictMode>
    <ChakraProvider disableGlobalStyle>
      <UserContextProvider>
        <RouterProvider router={router} />
      </UserContextProvider>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
