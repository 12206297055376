import { User } from "firebase/auth";
import * as React from "react";
import { useState, createContext } from "react";
import { auth } from "../firebase";


export interface UserCContext {
    user: User | null;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

export const UserContext = createContext<UserCContext | null>(null);

export const UserContextProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {

    const [user, setUser] = useState<User | null>(null);

    auth.onAuthStateChanged((change) => {
        setUser(change)
    })

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
