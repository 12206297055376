import { Slider, SliderFilledTrack, SliderProps, SliderThumb, SliderThumbProps, SliderTrack, SliderTrackProps } from "@chakra-ui/react";

type ISlider = {} & SliderProps;
export const WSlider = (props: ISlider) => {
    return <Slider {...props} />;
};

type ISliderTrack = {} & SliderTrackProps;
export const WSliderTrack = (props: ISliderTrack) => {
    return <SliderTrack {...props} />;
};

type ISliderThumb = {} & SliderThumbProps;
export const WSliderThumb = (props: ISliderThumb) => {
    return <SliderThumb {...props} />;
};

type ISliderFilledTrack = {};
export const WSliderFilledTrack = (props: ISliderFilledTrack) => {
    return <SliderFilledTrack {...props} />;
};
