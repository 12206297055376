import './App.scss';
import Player from './components/player/player';


function App() {

  return (
    <div className="App">

      <footer className="footer">
        <Player />
      </footer>
    </div>
  );
}

export default App;
