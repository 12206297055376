
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB3Uz7QnS2YuStvkd39lEw7KeH4xmXBACU",
  authDomain: "riwia-music-player.firebaseapp.com",
  projectId: "riwia-music-player",
  storageBucket: "riwia-music-player.appspot.com",
  messagingSenderId: "489295006985",
  appId: "1:489295006985:web:d098ca24bb84ae5c0357d8",
  measurementId: "G-N3J0HWCQB4"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
