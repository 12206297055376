import styles from "./modal.module.scss";
import { Modal, ModalContent, ModalContentProps, ModalProps } from "@chakra-ui/react";

type IModal = {} & ModalProps;
export const WModal = (props: IModal) => {
    return <Modal {...props} />;
};

type IModalContent = {} & ModalContentProps;
export const WModalContent = (props: IModalContent) => {
    return <ModalContent {...props} className={`${styles["modal"]} ${props.className}`} />;
};
